import React from 'react';
import BlogService from "../../services/blogService";
import Banner from '../Banner/banner';
import BannerService from "../../services/bannerService";
import Setting from '../../services/settingService';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

export default  class BlogDetail extends React.Component{

    constructor(props) {
        super(props);
        this.announcement_id = props.match.params.id;
        this.search = {start:0,perPage:1000000000,searchTxt:"",searchField:"" ,settingList:[]};
        this.state = {
            CurrentPage:"",
            blogList :null,
            index: 0,
            blogId : '',
            scrollPosition:400
        };

        this.blogServ = new BlogService();
        this.settingServ = new Setting();
        this.bannerServ = new BannerService();

           
    }
    
    componentDidMount(){
        this.getBannerList();
        if(this.announcement_id){
            this.blogServ.getBlog(this.announcement_id).then( 
                (response)=>{
                    response.data.description = ReactHtmlParser(response.data.description);
                    this.setState({CurrentPage: response.data});
                },
                (error) => {
                    alert("Oops, something went wrong not able to fetch Course  details.");
                }
            );
        } 
        this.getBlogList();
        this.settingfn();
    }
    shouldComponentUpdate(){
        return true
    }
   
    settingfn(){
        this.settingServ.listSetting(0,1000000
            )
            .then(
                (response)=>{
                    this.setState({settingList: response.data});
                },
                (error)=>{
                    this.setState({settingList: []});
                }
            );
    }

    scrollUp(amountToScroll){
        this.setState({
            scrollPosition : this.state.scrollPosition + amountToScroll
        })
        window.scrollTo(0, this.state.scrollPosition)
    }

    getBlogList(){
        this.blogServ.listBlog(
                this.search.start,this.search.perPage,
                this.search.searchTxt,this.search.searchField
            )
            .then(
                (response)=>{
                    let bloglist = response.data.filter((value)=>{
                        return value.type === 'event';
                    })
                    this.setState({blogList: bloglist});
                },
                (error)=>{
                    this.setState({blogList: []});
                }
            ); 
        }

        onClickNextPage = () => {
            let arr = this.state.blogList.length;
            let idx = this.state.index + 1;
            idx = idx % arr;
            this.setState({
                index: idx,
             
            }); 
            let id  = this.state.blogList[this.state.index]._id
            this.blog_id =id
            this.blogServ.getBlog(this.blog_id).then( 
                (response)=>{
                    response.data.description = ReactHtmlParser(response.data.description);
                    this.setState({CurrentPage: response.data});
                    this.forceUpdate()
                    this.props.match.params = this.state.CurrentPage._id
                   
                },
                (error) => {
                    alert("Oops, something went wrong not able to fetch Course  details.");
                }
            );
          }
        
          onClickPrePage = () => {
            let arr = this.state.blogList.length;
            let idx = this.state.index;
            
            if (idx === 0) {
               idx = arr - 1;
            } else {
              idx = idx -1;
            }
            
            this.setState({
              index: idx,
            }); 
            let id  = this.state.blogList[this.state.index]._id
            this.blog_id =id
            this.blogServ.getBlog(this.blog_id).then( 
                (response)=>{
                    response.data.description = ReactHtmlParser(response.data.description);
                    this.setState({CurrentPage: response.data});
                    this.forceUpdate()
                    this.props.match.params = this.state.CurrentPage._id
                   
                },
                (error) => {
                    alert("Oops, something went wrong not able to fetch Announcement  details.");
                }
            );


          }


          getBannerList() {
            this.bannerServ
              .listBanner(
                this.search.start,
                this.search.perPage,
                this.search.searchTxt,
                this.search.searchField
              )
              .then(
                response => {
                  this.bannerList = [];
                  for (let i = 0; i < response.data.length; i++) {
                    this.bannerList.push(response.data[i]);
                  }
                  
                  this.setState({ bannerList: response.data });
                },
                error => {
                  this.setState({ bannerList: [] });
                }
              );
          }
        

    render(){

    
        let currentpage = "";
        let title = "";
            let pagename = "";
            if (this.bannerList) {
            for (let i = 0; i < this.bannerList.length; i++) {
                if (this.bannerList[i].title === "Announcement Detail") {
                title = this.bannerList[i].title;
                pagename = this.bannerList[i].pagename;
                }
            }
            }
        if(this.state.CurrentPage){
            currentpage = <div className="item">
                <div className="thumb">
                <div className="info">
                <h3 className="title">
                {this.state.CurrentPage.title}<span> - {moment(this.state.CurrentPage.createdAt).format('DD/MM/YYYY')}</span></h3>
               
            </div>
            </div>
            <div className="info">
               
                {this.state.CurrentPage.description}
            </div>
            
        </div>
        }

        return(
            <>
            <Banner pagename={pagename} pagelink={"/blogs"} title= {title} showSearch={false} />
            <div className="blog-area full-blog right-sidebar single-blog full-blog default-padding">
            <div className="container">
                <div className="row">
                    <div className="blog-items">
                        <div className="blog-content col-md-15">
                            <div className="item-box">
                                {currentpage}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
        )
    }
}