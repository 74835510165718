import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import Logout from "./components/Logout";
import Login from "./pages/login/Login";
import CourseDetail from "./pages/course/course.detail";
import signUp from "./pages/login/signup";
import orderPreview from "./pages/order/previewOrder";
import ordersave from "./pages/order/saveOrder";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/contact";
import UserProfile from "./pages/userProfile/user.profile";
import CourseHome from "./pages/course/Course";
import Pg from "./pages/pg/pg";
// import Checkout from './pages/Payment/payment';
import ForgetPassword from "./pages/login/forget.password";
import ResetPassword from "./pages/login/reset.password";
import UserCourse from "./pages/userProfile/user.course";
import ForgetPasswordConfirmation from "./pages/login/forget.password.confirmation";
import Faq from "./pages/faq/faq";
import Blogs from "./pages/blogs/blogs";
import BlogDetail from "./pages/blogs/blogDetail";
import NewsDetail from "./pages/blogs/newsDetail";
import AnnouncementDetail from "./pages/blogs/announcementDetail";
import Header from "./pages/header/header";
import Footer from "./pages/footer/footer";
import { Error404 } from "./pages/ErrorPage/Error404";
import EventMetting from "./pages/Eventmetting/EventMetting";

import UtilsService from "./services/utilservice";
import CourseResult from "./pages/userProfile/CourseResult";
import { FeedBackForm } from "./pages/userProfile/FeedBackForm";
import PasswordChanged from "./pages/userProfile/PasswordChanged";
import Testimonial from "./pages/userProfile/testimonial";

export default class App extends React.Component {
  constructor() {
    super();
    this.state = { isLogin: false, userdata: null, access: true };
    if (window.user) {
      this.state.isLogin = true;
    }
    this.handlerLogin = this.handlerLogin.bind(this);
    this.handlerUserName = this.handlerUserName.bind(this);
  }

  componentDidMount() {
    this.checkIp();
  }

  handlerLogin(islogin) {
    this.setState({ isLogin: islogin });
  }
  handlerUserName(res) {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      let token = user.token;
      let newUsr = { ...res, token };
      window.user = newUsr;
      localStorage.setItem("user", JSON.stringify(newUsr));
    }
    this.setState({ userdata: res.data });
  }
  checkIp() {
    UtilsService.CheckIp()
      .then((res) => {
        this.setState({ access: true });
      })
      .catch((err) => {
        this.setState({ access: false });
      });
  }

  render() {
    return (
      <div className="App">
        {this.state.access ? (
          <div>
            <HashRouter>
              <section className="main-content">
                <Header isLogin={this.state.isLogin} updateUserData={this.state.userdata} />

                <Switch>
                  <Route
                    path="/login"
                    render={(props) => (
                      <Login
                        handlerLoginParent={this.handlerLogin.bind(this)}
                        handlerUserName={this.handlerUserName.bind(this)}
                        {...props}
                      />
                    )}
                  />

                  <Route exact path="/" render={(props) => <Home {...props} />} />
                  <Route
                    path="/logout"
                    render={(props) => <Logout {...props} handlerLoginParent={this.handlerLogin.bind(this)} />}
                  />
                  <Route exact path="/details/:id" component={CourseDetail} />
                  <Route exact path="/course" component={CourseHome} />
                  <Route exact path="/course/:searchTxt" component={CourseHome} />
                  <Route exact path="/course/category/:id" component={CourseHome} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/signup" component={signUp} />
                  <PrivateRoute exact path="/order/:id" component={orderPreview} />
                  <Route exact path="/order/tk/:token" component={orderPreview} />
                  <PrivateRoute exact path="/order/save/:id" component={ordersave} />
                  <Route
                    path="/user/profile"
                    render={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <Route path="/pg/:id" component={Pg} />
                  <Route path="/forgetpassword" component={ForgetPassword} />
                  <Route exact path="/reset/:token" component={ResetPassword} />
                  <Route exact path="/forgetpasswordconfirm" component={ForgetPasswordConfirmation} />
                  <Route exact path="/user/course/:id/:user" component={UserCourse} />
                  <Route exact path="/faq" component={Faq} />
                  <Route path="/blogs" component={Blogs} />
                  <Route path="/blog/:id" component={BlogDetail} />
                  <Route path="/news/:id" component={NewsDetail} />
                  <Route path="/announcements/:id" component={AnnouncementDetail} />
                  <Route path="/course/result/:id" component={CourseResult} />
                  <Route path="/feedback/:id" component={FeedBackForm} />
                  <Route path="/eventmeeting/:meetingId/:name" component={EventMetting} />
                  <Route path="/testimonial/:id" component={Testimonial} />
                  <Route
                    path="/change-password"
                    render={(props) => <PasswordChanged handleUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                </Switch>

                <Footer />
              </section>
            </HashRouter>
          </div>
        ) : (
          <HashRouter>
            <Route path="*" component={Error404} />
          </HashRouter>
        )}
      </div>
    );
  }
}
