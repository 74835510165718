import React from "react";
import { Link } from "react-router-dom";
import User from "../../services/userService";
import SettingLogo from "../../services/settingService";
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.userD = JSON.parse(localStorage.getItem("user"));
    this.settingServ = new SettingLogo();
    this.state = {
      logo: "",
    };
  }

  componentDidMount() {
    this.settingList();
  }

  async settingList() {
    try {
      let result = await this.settingServ.listSetting();
      result.data.forEach((logoUrl) => {
        if (logoUrl.name === "logo") {
          this.setState({ logo: logoUrl.value });
        }
      });
    } catch (err) {
      alert("Oops, something went wrong in setting");
    }
  }

  render() {
    let data;
    if (this.props.updateUserData?.image) {
      data = (
        <Link to="/user/profile" style={{ padding: "29px" }}>
          <img
            src={this.props?.updateUserData?.image || "/assets/img/default_image.jpeg"}
            className="img-avatar"
            alt="profile image"
            height="50px"
            width="50px"
          />{" "}
          Welcome <span>{this.props.updateUserData.first_name}</span>
        </Link>
      );
    } else if (window.user && window?.user.data?.image) {
      data = (
        <div class="">
          <Link
            to="/user/profile"
            className="navbar-brand"
            style={{ color: "#333333", marginTop: "6px", fontSize: "15px" }}
            to="/user/profile"
          >
            <img
              style={{ borderRadius: "50px" }}
              src={window?.user?.data?.image || "/assets/img/default_image.jpeg"}
              className="logo"
              alt="profile image"
              height="40px"
              width="40px"
            />{" "}
            Welcome <span>{window.user.data.first_name}</span>
          </Link>
        </div>
      );
    } else {
      data = (
        <Link to="/user/profile">
          <img
            src="/assets/img/default_image.jpeg"
            className="img-avatar"
            alt="profile image"
            height="80px"
            width="80px"
          />{" "}
          Welcome{" "}
          <span>
            {window.user && window.user.data && window.user.data.first_name ? window.user.data.first_name : " "}
          </span>
        </Link>
      );
    }
    let navLinks = [];
    if (this.props.isLogin) {
      navLinks = [
        <li key="2">
          <Link to="/logout">Log Out</Link>
        </li>,
        <li key="5">{data}</li>,
      ];
    } else {
      navLinks = [
        <li key="3">
          <Link to="/login">Log In</Link>
        </li>,
        <li key="4">
          <Link to="/signup">Sign Up</Link>
        </li>,
      ];
    }
    return (
      <nav class="navbar navbar-default attr-border navbar-sticky bootsnav on no-full">
        <div class="container">
          <div class="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
              <i className="fa fa-bars"></i>
            </button>

            <Link className="" to="/">
              <img
                src={this.state.logo}
                className="logo"
                alt="Logo"
                height="80px"
                width="80px"
                style={{ marginTop: "5px" }}
              />
            </Link>
          </div>
          <div class="collapse navbar-collapse" id="navbar-menu">
            <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
              <li className="dropdown">
                <Link to="/">Home</Link>
              </li>
              <li className="dropdown">
                <Link to="/course">Courses</Link>
              </li>
              <li>
                <Link to="/pg/about_us">About Us</Link>
              </li>
              <li>
                <Link to="/contact">contact</Link>
              </li>
              <li>
                <Link to="/blogs">News</Link>
              </li>
              {navLinks}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
