import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";

import QuizzeService from "../../../services/quizzeService";
import CreatedBySrv from "../../../services/userService";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Redirect, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Iframe from "react-iframe";
import { Formik, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Accordion from "react-bootstrap/Accordion";
import { Modal, Card, Button } from "react-bootstrap";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import T from "./QuizzeTimer";
import QuestionTimer from "./questionTimer";
import ResultScreen from "./ResultScreen";
import PriviewQuizze from "./previewQuizze";

function Quizze(props) {
  let quizzeServ = new QuizzeService();
  const [start, setstart] = useState(true);
  const [questionList, setquestionList] = useState([]);
  const [lastQustionSumbittedMsg, setlastQustionSumbittedMsg] = useState(false); //show message when last question submitted
  const [QuizzeDetail, setQuizzeDetail] = useState({ total_duration: 0 });
  const [currentQuestion, setcurrentQuestion] = useState(0);
  const [question, setquestion] = useState({ given_options: [], total_duration: null });
  const [show, setshow] = useState(false);
  const [btndisabled, setbtndisabled] = useState(false);
  const [timeupMessage, settimeupMessage] = useState(false); //each question  time up message;
  const [finalTimeUp, setfinalTimeUp] = useState(false); //final time up  total quize duaration
  const [showQuizzeMarks, setshowQuizzeMarks] = useState({}); // show quizze marks
  const [showResult, setshowResult] = useState(false);
  const [showNotEvaluated, setshowNotEvaluated] = useState(false);
  const [showQuestion, setshowQuestion] = useState(false);
  const [previewQuizze, setpreviewQuizze] = useState({}); // state for showing priview quizz
  const [ShowPreviewResult, setShowPreviewResult] = useState(false);
  const [retryRes, setretryRes] = useState(false); // calling question api again when user retry
  const handleShow = () => setshow(true);
  const handleClose = () => setshow(false);
  let quizTimerId;
  let questionTimerId;
  useEffect(() => {
    setquestion(questionList[currentQuestion]);
  }, [currentQuestion, questionList]);
  //submit  single question id
  const submitSection = (values, action) => {
    let obj = {
      question: values.question,
      user_answers:
        typeof values.user_answers == "string"
          ? JSON.stringify([values.user_answers])
          : JSON.stringify(values.user_answers) || "[]",
      option: values.given_options.length ? JSON.stringify(values.given_options) : values.option,
      correct_answer: JSON.stringify(values.correct_answers),
      userId: window.user.data._id,
      sectionId: props.sectionId,
      lessonId: props.lesson._id,
      quizzeId: props.lesson.quizeId,
      marks: values.marks,
      negativeMarks: values.negative_marks,
      answerType: values.answer_type,
      questionType: values.question_type,
      total_marks: QuizzeDetail.total_marks,
      isLastQuestion: false,
      filePath: values.file_path,
      marks_allocated: values.marks,
      negative_mark_alloted: values.negative_marks,
      quizzeCreatedBy: QuizzeDetail.created_by,
      questionId: values._id,
      courseId: props.courseId,
      min_marks: QuizzeDetail.min_marks,
      is_publish: QuizzeDetail.is_publish,
    };
    if (finalTimeUp) {
      //when time up then
      obj.isLastQuestion = true;
    }
    if (questionList.length - 1 == currentQuestion) {
      obj.isLastQuestion = true;
    }
    quizzeServ
      .SubmitQuizze(obj)
      .then((res) => {
        action.setSubmitting(false);
        if (questionList.length - 1 !== currentQuestion) {
          !finalTimeUp && setcurrentQuestion((c) => c + 1);
          settimeupMessage(false);
        } else {
          setlastQustionSumbittedMsg(true);
          setfinalTimeUp(false);
          setbtndisabled(true);
          clearInterval(questionTimerId);
          clearInterval(quizTimerId);
        }
        if (finalTimeUp) {
          //final time up then disable btn
          setbtndisabled(true);
        }
      })
      .catch((err) => {});
  };
  //clear timer when press next button
  useEffect(() => {
    clearInterval(questionTimerId);
  }, [currentQuestion]);
  useEffect(() => {
    let courseId = props.courseId;
    let lessonId = props.lesson._id;
    let quizzeId = props.lesson.quizeId;

    async function fetch() {
      try {
        const result = await quizzeServ.getQuizzeQuestion(courseId, lessonId, quizzeId);
        if (result.data.type == "result") {
          setshowResult(true);
          setshowNotEvaluated(false);
          setshowQuestion(false);
          setshowQuizzeMarks(result.data);
          setpreviewQuizze(result.data);
          setlastQustionSumbittedMsg(false);
        } else if (result.data.type == "notEvaluated") {
          setshowResult(false);
          setshowNotEvaluated(true);
          setshowQuestion(false);
          setshowNotEvaluated(result.data);
          setpreviewQuizze(result.data);
          setlastQustionSumbittedMsg(false);
        } else {
          setshowQuestion(true);
          setquestionList(result.data.questions);
          setQuizzeDetail(result.data);
          setshowResult(false);
          setshowNotEvaluated(false);
          setpreviewQuizze(null);
          setlastQustionSumbittedMsg(false);
        }
      } catch (error) {}
    }
    setbtndisabled(false);
    setcurrentQuestion(0);
    setShowPreviewResult(false);
    fetch();
  }, [props.lesson, retryRes]);

  //question type vedio , audio , pdf etc
  const handleQuestionType = (values) => {
    return (
      <Iframe
        url={values?.file_path.replace('http:','https:')}
        width="100%"
        height="300px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"
        allowFullScreen={true}
        controls
      />
    );
  };
  // handle text type answer type
  const handletexttype = (values, handleChange) => {
    return (
      <li key={values.question}>
        <input style={{ display: "none" }} type="text" value={values?.question} name="question" />

        <Form.Control
          type="text"
          onChange={(e) => {
            handleChange(e);
          }}
          name="user_answers"
          placeholder="Answer"
          disabled={timeupMessage}
        />
      </li>
    );
  };

  //handle single mcq and multiple mcq question
  const showmcq = (values, handleChange) => {
    return values?.given_options.map((el2, i2) => {
      if (values?.answer_type == "single_mcq" || values?.answer_type == "true/false") {
        return (
          <div className="col-md-6">
            <li key={el2 + i2 + "_" + values._id }>
              <input style={{ display: "none" }} type="text" value={values?.question} name="question" />

              <Form.Check
                style={{ display: "inline-grid" }}
                type="radio"
                value={el2}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="user_answers"
                disabled={finalTimeUp || timeupMessage}
              />
              <span className="radio-button">{el2}</span>
            </li>
          </div>
        );
      } else if (values?.answer_type == "multiple_mcq") {
        return (
          <div className="col-md-6">
            <li key={el2 + i2 + values._id }>
              <input style={{ display: "none" }} type="text" value={values?.question} name="question" />

              <Form.Check
                style={{ display: "inline-grid" }}
                type="checkbox"
                value={el2}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="user_answers"
                disabled={finalTimeUp || timeupMessage}
              />
              <span className="radio-button">{el2}</span>
            </li>
          </div>
        );
      }
    });
  };
  //handle each question timer Submission
  const handleTimerSubmission = (done) => {
    settimeupMessage(done);
  };
  //handle final timer Submission
  const handleFinalTimerSubmission = (done) => {
    setfinalTimeUp(done);
  };
  //handle question clear  interval
  const handleclearInterval = (intervalId) => {
    if (finalTimeUp) {
      clearInterval(intervalId);
    }
    questionTimerId = intervalId;
  };
  const handleclearIntervalQuizze = (intervalId) => {
    //quizze timer id
    quizTimerId = intervalId;
  };
  //handle reset/delete Question from database if they retry
  const handleTryAgain = () => {
    quizzeServ
      .resetQuizzeResult(props.lesson.quizeId)
      .then((res) => {
        handleClose();
        setretryRes(!retryRes);
        setbtndisabled(false);
        setcurrentQuestion(0);
      })
      .catch((err) => {});
  };
  if (ShowPreviewResult) {
    return <PriviewQuizze data={previewQuizze.result} />;
  }
  if (lastQustionSumbittedMsg) {
    return <div className="result">Quiz Submitted</div>;
  }
  if (showQuestion && !lastQustionSumbittedMsg) {
    if (start) {
      return (
        <div className="result">
          <Button
            onClick={() => {
              setstart(false);
            }}
            size="sm"
          >
            Start
          </Button>
        </div>
      );
    } else {
      return (
        <Row>
          <h4 style={{ backgroundColor: "#ccc", padding: "15px" }} class="panel-title">
            {props.lesson.name}
            <span style={{ float: "right", display: "flex" }}>
              {QuizzeDetail.total_duration ? (
                <>
                  {" "}
                  <strong>Total time left: </strong>
                  <T
                    handleTimer={handleFinalTimerSubmission}
                    minutes={+QuizzeDetail?.total_duration}
                    handletimerInterval={handleclearIntervalQuizze}
                  />
                </>
              ) : null}
            </span>
          </h4>{" "}
          <Formik initialValues={question} enableReinitialize={true} onSubmit={submitSection}>
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              setFieldValue,
              resetForm,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      Question {currentQuestion + 1} of {questionList.length}
                      <span style={{ float: "right" }}>
                        <QuestionTimer
                          minutes={values?.total_duration}
                          handleTimer={handleTimerSubmission}
                          handleClearInterval={handleclearInterval}
                        />
                      </span>
                    </h4>
                  </div>
                  <div class="panel-body">
                    <h4 class="panel-title">{values?.question.split('\n').map(str => <p>{str}</p>)}</h4>
                    <br />
                    <ul>
                      {values?.question_type !== "text" && handleQuestionType(values)}
                      <div className="row"></div>
                      <Form.Group>
                        {values?.answer_type == "text"
                          ? handletexttype(values, handleChange)
                          : showmcq(values, handleChange)}
                      </Form.Group>
                    </ul>
                  </div>
                  <Row>
                    <Col sm={12} md={4}>
                      <Button
                        disabled={btndisabled || isSubmitting}
                        className="btn btn-primary btn-block setbgcolor"
                        type="submit"
                      >
                        {finalTimeUp ? "Submit" : questionList.length - 1 !== currentQuestion ? "Next" : "Sumbit"}
                      </Button>
                    </Col>
                  </Row>
                  <div>
                    {finalTimeUp ? (
                      <div className="quizze-submitted-msg">Time Up Please Submit the Question</div>
                    ) : timeupMessage ? (
                      <div className="quizze-submitted-msg"> Press next button Time Up for this question</div>
                    ) : null}
                  </div>
                  {lastQustionSumbittedMsg || btndisabled ? (
                    <div className="quizze-submitted-msg" style={{ backgroundColor: "green" }}>
                      Quiz Submitted
                    </div>
                  ) : null}
                </Form>
              );
            }}
          </Formik>
        </Row>
      );
    }
  } else if (showResult) {
    if (previewQuizze?.is_preview || previewQuizze?.allowed_multiple_time) {
      return (
        <div className="">
          <div className="row result">
            <div className="col-xs-6">
              {previewQuizze?.is_preview && (
                <Button onClick={() => setShowPreviewResult(true)} size="sm" type="button">
                  Show preview
                </Button>
              )}
            </div>
            <div className="col-xs-6">
              {/* {previewQuizze?.allowed_multiple_time && (
                <Button onClick={() => handleShow()} size="sm" type="button">
                  Retry Again?{" "}
                </Button>
              )} */}
               <Button onClick={() => handleShow()} size="sm" type="button">
                  Retry Again?{" "}
                </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <p>
                Result:{" "}
                <span>
                  You got {showQuizzeMarks.marksObtained} out of {showQuizzeMarks.totalMarks} in{" "}
                  <strong>{props.lesson.name} </strong>test
                </span>
              </p>
            </div>
          </div>
          <Modal centered show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>Confirm Reset?</Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }} class="panel-heading">
                <h4 class="panel-title">Are you sure want to retry?</h4>
                <p>it will erase all your previous result. </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleTryAgain();
                }}
                size="sm"
              >
                Yes
              </Button>
              <Button onClick={() => handleClose()} size="sm">
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return (
        <ResultScreen
          marksObtained={showQuizzeMarks.marksObtained}
          totalMarks={showQuizzeMarks?.totalMarks}
          quizzeName={props.lesson.name}
        />
      );
    }
  } else if (showNotEvaluated) {
    if (previewQuizze?.is_preview || previewQuizze?.allowed_multiple_time) {
      return (
        <div className="result">
          <div className="row">
            <div className="col-xs-6">
              {previewQuizze?.is_preview && (
                <Button onClick={() => setShowPreviewResult(true)} size="sm" type="button">
                  Show preview
                </Button>
              )}
            </div>
            <div className="col-xs-6">
            <Button onClick={() => handleShow()} size="sm" type="button">
                  Retry Again?{" "}
                </Button>
            </div>
          </div>
          <Modal centered show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>Confirm Reset?</Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }} class="panel-heading">
                <h4 class="panel-title">Are you sure want to retry?</h4>
                <p>it will erase all your previous result. </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleTryAgain();
                }}
                size="sm"
              >
                Yes
              </Button>
              <Button onClick={() => handleClose()} size="sm">
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <div className="result">
            <div className="row">
            <Modal centered show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>Confirm Reset?</Modal.Header>
            <Modal.Body>
              <div style={{ textAlign: "center" }} class="panel-heading">
                <h4 class="panel-title">Are you sure want to retry?</h4>
                <p>it will erase all your previous result. </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleTryAgain();
                }}
                size="sm"
              >
                Yes
              </Button>
              <Button onClick={() => handleClose()} size="sm">
                No
              </Button>
            </Modal.Footer>
          </Modal>
          
            <div className="col-xs-6">
            Final result will be displayed here once teacher will review all your answer
              </div>
                  <div className="col-xs-6">
                  {/* {previewQuizze?.allowed_multiple_time && (
                    <Button onClick={() => handleShow()} size="sm" type="button">
                      Retry Again?{" "}
                    </Button>
                  )} */}
                  <Button onClick={() => handleShow()} size="sm" type="button">
                      Retry Again?{" "}
                    </Button>
                    </div>
              </div>
              </div>;
    }
  } else {
    return <div className="result">loading...</div>;
  }
}

export default Quizze;

//Final result will be displayed here once teacher will review all your answer
