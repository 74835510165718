import React from "react";
import ReactParser from "react-html-parser";
export default class Summary extends React.Component {
  render() {
    const { summary } = this.props;
    return (
      <div>
        <div style={{ marginBottom: "100px" }}>
          <aside>
            <div class="tab-info">
              <div class="tab-content tab-content-info">
                {/* <!-- Single Tab --> */}
                <div id="tab1" class="tab-pane fade active in">
                  <div class="info title">
                    {/* <!-- Start Course List --> */}
                    <div class="course-list-items acd-items acd-arrow">
                      <div class="panel-group symb" id="accordionn">
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a
                                style={{ border: "1px solid #ccc", paddingLeft: "10px" }}
                                data-toggle="collapse"
                                data-parent="#accordionn"
                                href={`#${2}`}
                                aria-expanded="false"
                                class="collapsed"
                              >
                                Summary
                              </a>
                            </h4>
                          </div>
                          <div
                            id={"2"}
                            class="panel-collapse collapse"
                            aria-expanded="false"
                            style={{ height: " 0px" }}
                          >
                            <div class="panel-body">
                              <ul>
                                <li>
                                  <div class="item name">{ReactParser(summary)}</div>
                                  <div class="item title"></div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Course List --> */}
                  </div>
                </div>

                {/* <!-- End Single Tab --> */}
              </div>
              {/* <!-- End Tab Content --> */}
            </div>
          </aside>
        </div>
        
      </div>
    );
  }
}
