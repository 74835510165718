import React from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import Banner from "../Banner/banner";
import CourseService from "../../services/courseService";
import userServices from "../../services/userService";
import CourseCategoryService from "../../services/courseCategoryService";
import BannerService from "../../services/bannerService";
import ReactHtmlParser from "react-html-parser";
import Testimonial from "../../services/testimonialService";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import setting from "../../services/settingService";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import LiveSessionCourse from "../userProfile/LiveSessionCourse";
export default class CourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.stud = window.user ? window.user.data._id : "";
    this.user = window.user;
    this.state = {
      course: "",
      courseCount: [],
      order: "",
      testimonialList: [],
      category: "",
      successMessage: false,
      settingList: [],
      modal: false,
      file: {},
    };
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "" };

    this.bannerServ = new BannerService();
    this.courseServ = new CourseService();
    this.courseCatServ = new CourseCategoryService();
    this.testimonialServ = new Testimonial();
    this.userserv = new userServices();
    this.settingserv = new setting();

    if (this.course_id) {
      this.courseServ.getPublicCourse(this.course_id).then(
        (response) => {
          
          this.categoriesOption = response.data.categories.map((el, i) => {
            return el.id.name;
          });

          this.setState({ course: response.data });
        },
        (error) => {
          alert("Opps, Something went wrong not able to fetch Course  details.");
        }
      );
    }
  }
  handleShow() {
    this.setState({ modal: true });
  }
  handleClose() {
    this.setState({ modal: false });
  }
  componentDidMount() {
    this.getCourseCount();
    this.getBannerList();
    this.getTestimonial();
    this.getSettingVal();
    if (this.stud) {
      this.getStudent();
    }
  }

  getBannerList() {
    this.bannerServ
      .listBanner(this.search.start, this.search.perPage, this.search.searchTxt, this.search.searchField)
      .then(
        (response) => {
          this.bannerList = [];
          for (let i = 0; i < response.data.length; i++) {
            this.bannerList.push(response.data[i]);
          }

          this.setState({ bannerList: response.data });
        },
        (error) => {
          this.setState({ bannerList: [] });
        }
      );
  }
  getSettingVal() {
    this.settingserv.listSetting(0, 10000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }
  getStudent() {
    this.userserv.getUser(this.stud).then(
      (response) => {
        this.stud = response.data;
      },
      (error) => {
        alert("Oops, something went wrong not able to fetch course  details.");
      }
    );
  }
  PlacedOrderZeroPrice(crs) {
    let { _id, is_active, period } = crs;
    let day = moment().add(period, "days").valueOf();
    let courses = [...this.stud.courses, { course: _id, is_active, end_time: day, is_course_completed: false }];
    this.stud.courses = courses;
    this.userserv.editUser(this.stud).then(
      (response) => {
        window.scroll(0, 0);
        this.setState({ successMessage: true });
      },
      (error) => {
        this.setState({ successMessage: false });
      }
    );
  }
  async handleDownload(id, e) {

    let fileName = this.state.course.course_brochure_pdf.split("/");
    fileName = fileName[fileName.length - 1];
    const url = window.apiurl + "/uploads/broucher_pdf/" + fileName;
    
    let token = window.user ? window.user.token : "no-token";
    fetch(url, {
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let downloadurl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = downloadurl;
        a.download = fileName;
        a.click();
      });
    });
  }
  getTestimonial() {
    this.testimonialServ.getlisttestimonial(this.course_id).then(
      (response) => {
        if (response == "") {
          return "";
        }
        this.setState({ testimonialList: response.data });
        this.createOwlCarousel();
      },
      (error) => {
        this.setState({ testimonialList: [] });
      }
    );
  }

  getCourseCount() {
    this.courseServ.totalCourseCount().then(
      (response) => {
        this.courseCountOption = [];
        let count = response.data;
        for (let i = 0; i < count.length; i++) {
          let catSplit = count[i][0].split("/");
          this.courseCountOption.push(
            <li key={"courseCat" + i}>
              <Link
                to={{
                  pathname: "/course/category/" + catSplit[catSplit.length - 1],
                }}
              >
                {catSplit[catSplit.length - 2]} <span>{count[i][1]}</span>
              </Link>
            </li>
          );
        }
        this.setState({ courseCount: response.data });
      },
      (error) => {
        this.setState({ courseCount: [] });
      }
    );
  }

  createOwlCarousel() {
    setTimeout(() => {
      window.jQuery(".clients-review-carousel").owlCarousel({
        loop: true,
        margin: 30,
        nav: true,
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        dots: true,
        autoplay: true,
        responsive: {
          0: {
            items: 1,
          },
        },
      });
    }, 600);
  }
  removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  }

  render() {
    let btnactive = "";
    let currencyVal = "";
    for (let i = 0; i < this.state.settingList.length; i++) {
      if (this.state.settingList[i].name === "currencySymbol") {
        currencyVal = this.state.settingList[i].value;
      }
    }
    if (this.state.course.course_brochure_pdf) {
      btnactive = (
        <button
          type="button"
          className="btn btn-dark effect broucherbtn "
          id="button2"
          onClick={this.handleDownload.bind(this, this.state.course._id)}
        >
          {" "}
          <i className="fas fa-chart-bar"></i> Brochure
        </button>
      );
    }

    let data = [];
    let detail = null;
    let enroll = "";
    let userImage = "";
    let title = "";
    let pagename = "";
    if (this.bannerList) {
      for (let i = 0; i < this.bannerList.length; i++) {
        if (this.bannerList[i].title === "Course Detail") {
          title = this.bannerList[i].title;
          pagename = this.bannerList[i].pagename;
        }
      }
    }
    if (this.user) {
      if (+this.state.course.price == 0) {
        enroll = (
          <div
            className="btn btn-dark effect btn-sm"
            onClick={() => {
              this.PlacedOrderZeroPrice(this.state.course);
            }}
          >
            {" "}
            <i className="fas fa-chart-bar"></i> Enrol
          </div>
        );
      } else {
        enroll = (
          <Link className="btn btn-dark effect btn-sm" to={{ pathname: "/order/" + this.course_id }}>
            {" "}
            <i className="fas fa-chart-bar"></i> Enrol
          </Link>
        );
      }
    } else if (!this.user) {
      enroll = (
        <Link className="btn btn-dark effect btn-sm" to={{ pathname: "/login/" }}>
          {" "}
          <i className="fas fa-chart-bar"></i> Enrol
        </Link>
      );
    }

    if (this.state.testimonialList.length > 0) {
      for (let i = 0; i < this.state.testimonialList.length && i < 5; i++) {
        data.push(
          <>
            <div className="item item-course-det-t">
              <div className="col-md-12 info">
                <p>{this.state.testimonialList[i].content}</p>
                <h4 className="text-center">{this.state.testimonialList[i].name}</h4>
                <span className="text-center">{this.state.testimonialList[i].course.name}</span>
              </div>
            </div>
          </>
        );
      }
    }

    if (this.state.course) {
      if (!this.state.course.created_by || this.state.course.created_by.image == "") {
        userImage = <img alt="author" src="/assets/img/team/2.jpg" />;
      } else {
        userImage = <img src={this.state.course.created_by.image} alt="author" />;
      }
      detail = (
        <div>
          <Banner pagename={pagename} pagelink={"/details/:id"} title={title} showSearch={false} />
          {this.state.successMessage && (
            <div style={{ marginTop: "15px" }} className="alert alert-success">
              Course enrolled successfully
            </div>
          )}
          <div className={`course-details-area ${!this.state.successMessage ? "default-padding " : null}`}>
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="course-details-info">
                    <div className="top-info">
                      <div className="title">
                        <h2>{this.state.course.name}</h2>
                      </div>
                      <div className="thumb">
                        <img src="assets/img/courses/vertical.jpg" alt="Thumb" />
                      </div>
                      <div className="course-meta">
                        <div className="item author">
                          <div className="thumb">{userImage}</div>
                          <div className="desc">
                            <h4>Author</h4>
                            <span>{this.state.course.created_by ? this.state.course.created_by.first_name : ""}</span>
                          </div>
                        </div>
                        <div className="item category">
                          <h4>Category</h4>
                          <span>
                            {this.categoriesOption.map((el, i) => {
                              return (
                                <>
                                  {el ? el : null}
                                  {el && <br />}
                                </>
                              );
                            })}
                          </span>
                        </div>
                        <div className=" item price">
                          <h4>Price</h4>
                          <span>
                            {currencyVal} {this.state.course.price}
                          </span>
                        </div>
                        <div className="item price">
                          <h4>Course Duration</h4>
                          <span>{this.state.course.period} days</span>
                        </div>
                        <div className="align-right">{enroll}</div>
                      </div>
                      {/* <!-- End Course Meta --> */}
                    </div>
                    {/* <!-- End Top Info -->

                        <!-- Star Tab Info --> */}
                    <div className="tab-info">
                      <div className="tab-content tab-content-info">
                        {/* <!-- Single Tab --> */}
                        <div id="tab2" className="tab-pane fade active in">
                          <div className="info title">
                            <p>{ReactHtmlParser(this.state.course.description)}</p>
                            {this.state.course.course_type === "live_session" ? 
                            <div className="row">
                              <div className="col-md-12">
                                <LiveSessionCourse
                                  displayAccordian = {true}
                                  startDate={this.state.course.start_date}
                                  endDate={this.state.course.end_date}
                                  startTime={this.state.course.start_time}
                                  endTime={this.state.course.end_time}
                                  type={this.state.course.time}
                                  weekVal={this.state.course.weekDays}
                                  selectedDays={this.state.course.selectedDays}
                                />
                              </div>
                            </div>:<></>}
                            {this.state.course && this.state.course.section.length ? <h4>List Of Course Sections</h4> : <></>}
                            {/* <!-- Start Course List --> */}
                            {this.state.course.section &&
                              this.state.course.section.map((el, i) => {
                                return (
                                  <div className="course-list-items acd-items acd-arrow">
                                    <div className="panel-group symb" id="accordion">
                                      <div className="panel panel-default">
                                        <div className="panel-heading">
                                          <h4 className="panel-title">
                                            <a
                                              data-toggle="collapse"
                                              data-parent="#accordion"
                                              href={`#${i}`}
                                              aria-expanded="false"
                                              className="collapsed"
                                            >
                                              {el.name}
                                            </a>
                                          </h4>
                                        </div>
                                        <div
                                          id={i}
                                          className="panel-collapse collapse"
                                          aria-expanded="false"
                                          style={{ height: " 0px" }}
                                        >
                                          <div className="panel-body">
                                            <ul>
                                              {el.lesson.map((el2, i2) => {
                                                return (
                                                  <li>
                                                    <div className="item name">
                                                      {el2.lesson_type == "video" ? (
                                                        <i className="fas fa-video"></i>
                                                      ) : (
                                                        <i className="fas fa-file-alt"></i>
                                                      )}{" "}
                                                    </div>
                                                    <div className="item title">
                                                      <h5>{el2.name}</h5>
                                                    </div>
                                                    <div className="item info">
                                                      {el2.available_for_preview && (
                                                        <a
                                                          onClick={() => {
                                                            this.setState({ file: el2, modal: true });
                                                          }}
                                                          style={{ cursor: "pointer" }}
                                                        >
                                                          Preview
                                                        </a>
                                                      )}{" "}
                                                      <span>{el2.view_duration}</span>
                                                    </div>
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {/* <!-- End Course List --> */}
                          </div>
                        </div>

                        {/* <!-- End Single Tab --> */}
                      </div>
                      {/* <!-- End Tab Content --> */}
                    </div>
                    {/* <!-- End Tab Info --> */}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="sidebar">
                    <aside>
                      <div className="sidebar-item category">
                        <div className="broucherbtn">{btnactive}</div>
                        <div className="title">
                          <h4 className="crs">Courses Category</h4>
                        </div>
                        <div className="sidebar-info">
                          <ul>{this.courseCountOption}</ul>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8 col-md-offset-2 course-testimonial-pd-1">
                  <div className="testimonials-area carousel-shadow">
                    <div className="row">
                      <div className="clients-review-carousel owl-carousel owl-theme">{data}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        <Helmet>
          <title>{this.state.course.name}</title>
          <meta name="keywords" content={this.state.course.meta_keywords} />
          <meta name="description" content={this.state.course.meta_description} />
        </Helmet>

        {detail}
        <>
          <Modal show={this.state.modal} onHide={this.handleClose.bind(this)} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.file.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Iframe
                url={this.state.file.file_path && this.state.file.file_path.replace('http:','https:')}
                width="550px"
                height="500px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"
                allowFullScreen={true}
                controls
              />
            </Modal.Body>
          </Modal>
        </>
      </>
    );
  }
}
