import React from "react";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import Banner from "../Banner/banner";
import CourseService from "../../services/courseService";
import userServices from "../../services/userService";
import CourseCategoryService from "../../services/courseCategoryService";
import BannerService from "../../services/bannerService";
import ReactHtmlParser from "react-html-parser";
import Testimonial from "../../services/testimonialService";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import setting from "../../services/settingService";
import moment from "moment";
import { Modal, Button } from "react-bootstrap";
import Quizze from "./Quizze/Quizze";
import CourseQuestion from "./CourseEvaluationQus.json";
import BackButton from "../../components/BackButton";
export default class CourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.user = window.user;
    this.ifrme = React.createRef()
    this.state = {
      isFullScreen :false,
      viewedLessonList: [] /*lesson viewed list*/,
      lessonChangeCounter: 0,
      course: {
        section: [
          {
            name: "",
            sequence: "",
            lesson: [
              {
                name: "",
                view_duration: "",
                file_path: "",
                lesson_type: "",
                available_for_preview: false,
              },
            ],
          },
        ],
      },
      successMessage: false,
      settingList: [],
      modal: false,
      file: {
        question: "",
        question_type: "",
        answer_type: "",
        file_path: "",
        Sequence: "",
        option: "",
      },
      sectionId: "",
    };
    this.search = { start: 0, perPage: 12, searchTxt: "", searchField: "" };

    this.bannerServ = new BannerService();
    this.courseServ = new CourseService();

    if (this.course_id) {
      this.courseServ.getCourse(this.course_id).then(
        (response) => {
          this.categoriesOption = [];
          let categoriesData = response.data;
          for (let i = 0; i < categoriesData.categories.length; i++) {
            this.categoriesOption.push(categoriesData.categories[i].id.name + "||");
          }
          this.setState({ course: response.data });
        },
        (error) => {
          alert("Oops, something went wrong not able to fetch Course  details.");
        }
      );
    }
  }
  componentDidMount() {
    this.listViewedCourse();
  }

  handleShow() {
    this.setState({ modal: true });
  }
  handleClose() {
    this.setState({ modal: false });
  }
  handleViewedCourse(section, lesson) {
    let sectionId = section._id;
    let lessonId = lesson._id;
    let userId = window.user.data._id;
    let courseId = this.course_id;
    this.courseServ
      .handleViewedCourse({ sectionId, lessonId, userId, courseId })
      .then((res) => {
        let viewedLesson = [...this.state.viewedLessonList];
        viewedLesson.push(res.data);
        this.setState({ viewedLessonList: viewedLesson });
      })
      .catch((err) => {});
  }
  listViewedCourse() {
    let userId = window.user.data._id;
    let courseId = this.course_id;
    this.courseServ
      .ListViewedCourse({ userId, courseId })
      .then((res) => {
        this.setState({ viewedLessonList: res.data });
      })
      .catch((err) => {
        this.setState({ viewedLessonList: [] });
      });
  }
  
  toggleFullScreen = ()=> {
    if (!document.fullscreenElement) {
      document.querySelector("#iframe-pdf").requestFullscreen()
      this.setState({isFullScreen : true})
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen(); 
        this.setState({isFullScreen : false})
      }
    }
  }
  render() {
    
    let detail = null;
    if (this.state.course) {
      detail = (
        <div>
          <div style={{ marginBottom: "100px" }}>
            <div className="container">
              <div className="row">
                <div class="col-md-8" style={{ borderRight: "1px solid #E0E0E0" }}>
                  {this.state.file.lesson_type == "quize" ? (
                    <Quizze lesson={this.state.file} sectionId={this.state.sectionId} courseId={this.course_id} />
                  ) : (
                    <>
                    <div id ="iframe-pdf" ref ={(ref)=>this.ifrme =ref}>
                   {this.state.file.lesson_type == "pdf" && <i title="Full Screen" onClick={this.toggleFullScreen} className="fa fa-arrows-alt fullscreen" aria-hidden="true"></i>} 
                      
                    {this.state.file.lesson_type == "video" ?
                     <video  width="100%" height= {!this.state.isFullScreen? "437px" : "1000px"} controls>
                     <source src={ (this.state.file.file_path || this.state?.course.section[0]?.lesson[0]?.file_path).replace('http:','https:') } type="video/mp4" />
                     <source src="mov_bbb.ogg" type="video/ogg" />
                      Your browser does not support HTML video.
                      </video> :
                     <Iframe
                  
                     url={ (this.state.file.file_path || this.state?.course.section[0]?.lesson[0]?.file_path).replace('http:','https:') }
                     width="100%"
                     height= {!this.state.isFullScreen? "437px" : "1000px"}
                     id="myId"
                     className="myClassname"
                     display="initial"
                     position="relative"
                     allowFullScreen={true}
                     controls
                   />
                   }
                   
                    </div>
                    </>
                  )}
                </div>
                <div className="col-md-4">
                  <div className="sidebar">
                    <aside>
                      <div class="tab-info">
                        <div class="tab-content tab-content-info" style={{ marginTop: "12px" }}>
                          {/* <!-- Single Tab --> */}
                          <div id="tab2" class="tab-pane fade active in">
                            <div class="info title">
                              {/* <!-- Start Course List --> */}
                              {this.state.course.section &&
                                this.state.course.section.map((el, i) => {
                                  return (
                                    <div class="course-list-items acd-items acd-arrow">
                                      <div class="panel-group symb" id="accordion">
                                        <div class="panel panel-default">
                                          <div class="panel-heading">
                                            <h4 class="panel-title">
                                              <a
                                                data-toggle="collapse"
                                                data-parent="#accordion"
                                                href={`#${i}`}
                                                aria-expanded="false"
                                                class="collapsed"
                                              >
                                                {el.name}
                                              </a>
                                            </h4>
                                          </div>
                                          <div
                                            id={i}
                                            class="panel-collapse collapse"
                                            aria-expanded="false"
                                            style={{ height: " 0px" }}
                                          >
                                            <div class="panel-body">
                                              <ul>
                                                {el.lesson.map((el2, i2) => {
                                                  return (
                                                    <li
                                                      key={el2._id}
                                                      style={{ cursor: "pointer" }}
                                                      onClick={() => {
                                                        this.handleViewedCourse(el, el2);
                                                        this.setState({
                                                          file: el2,
                                                          modal: true,
                                                          sectionId: el._id,
                                                          lessonChangeCounter: this.state.lessonChangeCounter + 1,
                                                        });
                                                      }}
                                                    >
                                                      <div className="row">
                                                        <div class="item title col-xs-9">
                                                          <h5>{el2.name}</h5>
                                                        </div>
                                                        <div class="item info col-xs-3">
                                                          <span>
                                                            {el2.lesson_type == "video" ? (
                                                              <i className="fas fa-video"></i>
                                                            ) : (
                                                              <i class="fas fa-file-alt"></i>
                                                            )}{" "}
                                                            {el2.view_duration}
                                                          </span>
                                                          <span>
                                                            {this.state.viewedLessonList.map((vl) => {
                                                              if (vl.lessonId == el2._id) {
                                                                return (
                                                                  <i
                                                                    style={{ color: "green" }}
                                                                    className="fas fa-check-circle"
                                                                  ></i>
                                                                );
                                                              }
                                                            })}
                                                          </span>
                                                          <a></a>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              {/* <!-- End Course List --> */}
                            </div>
                          </div>

                          {/* <!-- End Single Tab --> */}
                        </div>
                        {/* <!-- End Tab Content --> */}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{this.state.course.name}</title>
          <meta name="keywords" content={this.state.course.meta_keywords} />
          <meta name="description" content={this.state.course.meta_description} />
        </Helmet>
        <div className="row crs-name">
          <div className="col-sm-6 col-xs-6 name">
            <BackButton textColor="white" />
            <div className="coursetitle">{this.state.course.name}</div>
          </div>
          <div className="col-sm-6 col-xs-6">
          <div className="feedback-internal">
              <Link
                  className="crs-btn"
                  style = {{"marginLeft": "3px"}}
                  to={
                    `/testimonial/${this.course_id}`
                  }
                >
                    Add testimonial
                </Link>
            </div>&nbsp;&nbsp;
            {this.state.course.course_type !== "live_session" && (
              <div className="feedback-internal">
                <Link
                  className="crs"
                  to={{
                    pathname: `/feedback/${this.state.course._id}`,
                    state: {
                      questions: CourseQuestion,
                      title: "Course Evaluation Form",
                      type: "course_evaluation",
                      teacherId: this.state.course?.created_by?._id,
                    },
                  }}
                >
                  Feedback
                </Link>
              </div>
            )}
          </div>
        </div>
        {detail}
      </>
    );
  }
}
